import React, { useEffect } from "react";
import UserManager from "./UserManager";

const CallBack = (props) => {
  const { history } = props;
  useEffect(() => {
    const signinRedirectCallback = () => {
      UserManager.Oidc.signinRedirectCallback()
        .then((user) => {
          try {
            const persistRedirectUrl = localStorage.getItem("redirectUrl");
            localStorage.setItem("token", user.id_token);
            localStorage.setItem("email", user.profile.email);
            localStorage.setItem("user", user);
            // localStorage.setItem("userid", user.profile.email);
            if (persistRedirectUrl) {
              history.push(persistRedirectUrl);
            } else {
              history.push("/");
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch(async (ex) => {
          console.log(ex);

          const retrySignIn = parseInt(
            sessionStorage.getItem("retrySignIn") || "0",
            10
          );
          console.log("Err: 30", retrySignIn);
          if (retrySignIn > 0) {
            sessionStorage.setItem("retrySignIn", `${1}`);
            // await UserManager.Oidc.signoutRedirect();
            console.log("Something went wrong!");

          } else {
            sessionStorage.setItem("retrySignIn", `${1}`);
            await UserManager.Oidc.signinRedirect();
          }
        });
    };
    signinRedirectCallback();
  }, [history]);

  return <></>;
};
export default CallBack;
