import axios from "axios";
import { API_GATEWAY_ENDPOINT } from "../environment_constants";


export function get_local_claims(localStorage){
  let local_claims = {
    "email": localStorage.getItem("email"),
    "id_token": localStorage.getItem("token")
  };

  return local_claims;
}
  
export async function registerCampaign(formRequest, local_token) {
    let headers = {
      "Content-Type": "application/json",
      "x-api-key": local_token["id_token"]
    };
    let url = API_GATEWAY_ENDPOINT;
    const response = axios.post(url, formRequest, headers);
    let response_string = await response;
    return response_string;
  }


export async function getBookings(local_token) {
    let url = API_GATEWAY_ENDPOINT;
    const response = axios.get(url, {headers:{"x-api-key": local_token["id_token"]}});
    let response_string = await response;
    let json_object = response_string.data;
    return json_object;
  }


export async function acknowledgeCampaign(formRequest, local_token) {
    let url = API_GATEWAY_ENDPOINT;
    const response = axios.put(url, formRequest, {headers:{"x-api-key": local_token["id_token"]}});
    let response_string = await response;
    return response_string;
  }