import React, { Component } from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Cookies from "js-cookie";

class CookieAcknowledgement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_popup: true
        };
        this.accept_cookies = this.accept_cookies.bind(this);
        this.rejected_cookies = this.rejected_cookies.bind(this);
    }

    accept_cookies() {
        Cookies.set('a3desk_cookies_accepted', 'true');
        this.setState({show_popup: false});
    }

    rejected_cookies() {
        Cookies.set('a3desk_cookies_accepted', null);
        this.setState({show_popup: false});
    }

    render() {      
        console.log(window.location.origin);
        let self = this;
        let a3desk_cookies_accepted_string = Cookies.get('a3desk_cookies_accepted', { domain: window.location.origin });
        let a3desk_cookies_accepted = (a3desk_cookies_accepted_string!= null && a3desk_cookies_accepted_string.toLowerCase()==="true")? true : false;
        let show_popup = this.state.show_popup;

      return (
        <div>
        {(a3desk_cookies_accepted || !show_popup) ?
        <span></span>:
        <Container className='cookie_ack'>
            <Row>
                <Col>
                    <h2>We Care About Your Privacy</h2>
                    <p>
                        We and our partners store and/or access information on a device, such as unique IDs in cookies to process personal data. 
                        You may accept or manage your choices by clicking below or at any time in the privacy policy page. 
                        These choices will be signaled to our partners and will not affect browsing data.<a href="https://www.thetradedesk.com/us/cookie-notice">Cookie Policy</a>
                    </p>
                </Col>
                <Col>
                    <Button variant="outline-primary" size="lg" onClick={self.accept_cookies}>I Accept</Button>{' '}
                    <Button variant="outline-primary" size="lg" onClick={self.rejected_cookies}>Reject All</Button>                    
                </Col>
            </Row>            
        </Container>}
        </div>
        
      )
    }
}

export default CookieAcknowledgement;