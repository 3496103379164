import React, { Component } from 'react';
import PropTypes from "prop-types";

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import { get_local_claims } from '../services/api_caller';

class A3DeskNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let user_claims = get_local_claims(localStorage);

        return (            
            
            <Navbar className="atres_navbar"  bg="transparent" variant="dark">
                <Navbar.Brand href="#home">
                    <img
                        src="/images/atresmedia_transparente.png"
                        width="100px"
                        className="d-inline-block align-top"
                        alt="ATRESMEDIA" />
                </Navbar.Brand>
                 <Navbar.Brand href="#home">
                    <img
                        src="/images/TheTradeDesk_Logo_White.png"
                        width="150px"
                        className="d-inline-block align-middle"
                        alt="TTD" />                        
                </Navbar.Brand>
                <Navbar.Collapse>
                    {user_claims["email"]!=null && <Nav.Link className="atres_navlink" href="/">Form</Nav.Link>}
                    {(user_claims["email"]!=null && (user_claims["email"].indexOf("@thetradedesk.com")>0 || user_claims["email"].indexOf("@atresmedia.com"))) &&<Nav.Link className="atres_navlink" href="/booking_list">Booking List</Nav.Link>}
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end">                  
                    {user_claims["email"]!=null && <Nav.Link href="#link" className="justify-content-end"><Button onClick={this.props.logout}>Log out</Button></Nav.Link>}
                </Navbar.Collapse>
                
            </Navbar>
        )
    }
}

A3DeskNavbar.propTypes = {
    isAuthenticated: PropTypes.bool,
    logout: PropTypes.func,
    login: PropTypes.func
}

export default A3DeskNavbar;