import React, { Component } from 'react';
import PropTypes from "prop-types";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import {Container, Spinner} from 'react-bootstrap';
import CookieAcknowledgement from "./cookie_acknowledgement";


// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import { registerCampaign, get_local_claims } from '../services/api_caller';
import Image from 'react-bootstrap/Image';
import es from "date-fns/locale/es"; // the locale you want



class AtresForm extends Component {
    constructor(props) {
        super(props);

        this.state = { 
          validated: false,
          showMessage: false,
          message: "",
          submit_status: false,
          submitting: false,
          form_anunciante: "",
          form_marca: "",
          form_agencia: "",
          form_presupuesto: 0,
          form_producto: "",          
          form_target: "",
          form_duracion_spot: 0,
          form_clave: "",
          form_observaciones: "",          
          cpm_on_target: -1,
          cpm_total: -1,
          display_calendar: false,
          date_range: [new Date(), new Date()],
          contactos_totales: 0,
          validation_errors: [],
          conversion: 0
        }; 
        this.submit_form = this.submit_form.bind(this);
        this.update_value = this.update_value.bind(this);
        this.atresmedia_form = React.createRef();

        this.date_change = this.date_change.bind(this);
        this.lookup_cpm = this.lookup_cpm.bind(this);
        this.submit_form_button = this.submit_form_button.bind(this);
        this.submit_to_api = this.submit_to_api.bind(this);


        this.cookies_accepted = this.cookies_accepted.bind(this);
    }

    lookup_cpm(){
      let cpm_matrix = [
            {"target": "Ind. 4+", "producto": "Antena 3", "cpm": 3.9},
            {"target": "Ind. 4+", "producto": "La Sexta", "cpm": 3.9},
            {"target": "Ind. 4+", "producto": "Multi", "cpm": 3.4},
            {"target": "Ind. 4+", "producto": "El Hormiguero", "cpm": 20}
          ];


      let duration_spot_list = [        
        {"Duration": '5"', "Adjustment": 1.6},
        {"Duration": '10"', "Adjustment": 1.4},
        {"Duration": '15"', "Adjustment": 1.2},
        {"Duration": '20"', "Adjustment": 1.0},
        {"Duration": '25"', "Adjustment": 1.25},
        {"Duration": '30"', "Adjustment": 1.50},
        {"Duration": '35"', "Adjustment": 1.75},
        {"Duration": '40"', "Adjustment": 2.00},
        {"Duration": '45"', "Adjustment": 2.25},
        {"Duration": '50"', "Adjustment": 2.50},
        {"Duration": '55"', "Adjustment": 2.75},
        {"Duration": '60"', "Adjustment": 3.00},        
        ]

      let conversions = [
          {"target": "Ind. 4+", "percentage": 1}
        ];

        let producto = this.state.form_producto.toString();
        let target = this.state.form_target.toString();
        let duracion_spot = this.state.form_duracion_spot.toString();


        let result = {"cpm": -1, "conversion": -1, "contactos_totales": 0};

        if(producto !== "" && target!=="" && duracion_spot!=="") {
          let cpm_total =  cpm_matrix.find(o => o["target"] === target && o["producto"] === producto)["cpm"];
          let selected_conversion =  conversions.find(o => o["target"] === target);
          let selected_duration_element = duration_spot_list.find(d=>d["Duration"]===duracion_spot);
          let selected_duration_adjustment = null;

          if(selected_duration_element!==null && selected_duration_element) {
            selected_duration_adjustment=selected_duration_element["Adjustment"]
          }


          if(cpm_total!==null && selected_conversion!==null && selected_duration_adjustment!==null){
            let selected_conversion_percentage = selected_conversion["percentage"];
            let adjusted_cpm = cpm_total* selected_duration_adjustment;
            let contactos_totales = (this.state.form_presupuesto/adjusted_cpm*1000) /selected_conversion_percentage;

            result = {
                "cpm_total": adjusted_cpm.toFixed(2),
                "conversion": selected_conversion_percentage, 
                "contactos_totales": Math.ceil(contactos_totales),
              };
          }
        }
        return result;
    }



    
    submit_form_button(event){
      let self = this;
      this.setState({"submitting": true}, ()=>{
        self.submit_to_api();
      });
    }

    async submit_to_api() {
      let form_data = {
        anunciante: this.state.form_anunciante,
        marca: this.state.form_marca,
        agencia: this.state.form_agencia,
        presupuesto: this.state.form_presupuesto,
        producto: this.state.form_producto,
        date_range_start: this.state.date_range[0].toISOString().split('T')[0],
        date_range_end: this.state.date_range[1].toISOString().split('T')[0],        
        target: this.state.form_target,
        duracion_spot: this.state.form_duracion_spot,
        clave: this.state.form_clave,
        observaciones: this.state.form_observaciones,
        cpm_total: this.state.cpm_total,
        contactos_totales: this.state.contactos_totales,
        user_email: this.props.email
      }; 

      let response = null;
      try {
        response = await registerCampaign(form_data, get_local_claims(localStorage));
        if(response.status===200){
          this.setState({
            validated: true,
            showMessage: true,
            submit_status: true,
            submitting: false,
            message: "Campaign submitted successfully!"});
        }
        return response;
      } 
      catch (error) {
          this.setState({
            validated: true,
            showMessage: true,
            submit_status: false,
            submitting: false,
            message: "Failed to submit campaign!"});
        }
        return response;
    }
    async submit_form(event) {
      if(event) 
        event.preventDefault();

      const form = this.atresmedia_form.current;
      let form_is_valid = form.checkValidity();

      if (form_is_valid === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      if(form_is_valid) {
      let self = this;
      this.setState({"submitting": true}, ()=>{
            self.submit_to_api();
          });
      }

        else{
          this.setState({validated: true});
        }
    }

    update_value(event){
      let input_value = event.target.value;
      let input_type = event.target.type;
      let field_name = event.target.id;

      switch(input_type){
        case "number":
          if(input_value>0){           
            this.setState({[field_name]: input_value}, ()=>{
              let result = this.lookup_cpm();
              this.setState({"contactos_totales": result["contactos_totales"], "cpm_total": result["cpm_total"], "conversion": result["conversion"]});
            });
          }
          break;

        default:          
          this.setState({ [field_name]: input_value}, ()=>{
            let result = this.lookup_cpm();
            this.setState({"contactos_totales": result["contactos_totales"], "cpm_total": result["cpm_total"], "conversion": result["conversion"]});
          });
          break;
      }
    }

    date_change(range1){
      console.log(range1);

      this.setState({date_range: range1});
    }

    componentDidMount(){      
      const today = new Date();
      const tomorrow = new Date(today);
      const next_week = new Date(today);
      next_week.setDate(tomorrow.getDate() + 8);
      this.setState({date_range: [tomorrow, next_week]});
    }

    cookies_accepted(){
      console.log("cookies_accepted!");
    }

    render() {
      this.lookup_cpm();
      let can_submit_form = !(this.state.cpm_total>0);

      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);


        return (            
        <div>
          <div className='atres_form_container'>
          <Alert className="form_alert" show={this.state.showMessage} variant={this.state.submit_status?"success": "danger"} dismissible onClose={() => this.setState({showMessage: false})} >
              <Alert.Heading>{this.state.submit_status?"Succesful!": "Failed!"}</Alert.Heading>
              <p>{this.state.message}</p>
          </Alert>

          <Form ref={this.atresmedia_form} noValidate validated={this.state.validated} onSubmit={this.submit_form} className="atresmedia_form">          
            <Row className="mb-3">
                <Col colSpan={6}>
                  <h1>1. KPIs a Rellenar</h1>
                </Col>
              </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="form_group" controlId="form_anunciante">
                <Form.Label>ANUNCIANTE</Form.Label>
                <Form.Control placeholder="ANUNCIANTE" type="text" onChange={this.update_value} required />
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_marca">
                <Form.Label>MARCA</Form.Label>
                <Form.Control placeholder="MARCA" type="text" onChange={this.update_value} required />
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_agencia">
                <Form.Label>AGENCIA</Form.Label>
                <Form.Control placeholder="AGENCIA" type="text" onChange={this.update_value} required />
              </Form.Group>              
              <Form.Group as={Col} className="form_group" controlId="form_presupuesto">
                <Form.Label>PRESUPUESTO</Form.Label>
                <Form.Control placeholder="PRESUPUESTO" type="number" onChange={this.update_value} required />
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_producto">
                <Form.Label>PRODUCTO</Form.Label>
                <Form.Select onChange={this.update_value} required >
                  <option value="">(seleccione)</option>
                  <option>Antena 3</option>
                  <option>La Sexta</option>
                  <option>Multi</option>
                  <option>El Hormiguero</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_duracion_campana">
                <Form.Label>FECHA INI-FIN CAMPAÑA</Form.Label>
                {/* <DateRangePicker 
                  onChange={this.date_change}
                  value={this.state.date_range}
                  minDate={tomorrow}
                  required /> */}

                <DatePicker
                  selected={this.state.date_range[0]}
                  selectsRange
                  startDate={this.state.date_range[0]}
                  endDate={this.state.date_range[1]}
                  onChange={this.date_change}
                  locale={es}
                  dateFormat="dd/MM/yyyy" />

              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_target">
                <Form.Label>TARGET</Form.Label>
                <Form.Select onChange={this.update_value} required >
                  <option value="">(seleccione)</option>
                  <option>Ind. 4+</option>
                  
                  {/* <option>Hombres 16+</option>
                  <option>Ind. 25-54</option>
                  <option>Mujeres 16+</option> */}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_duracion_spot">
                <Form.Label>DURACIÓN SPOT</Form.Label>
                <Form.Select onChange={this.update_value} required >
                  <option value="">(seleccione)</option>
                  <option>5"</option>
                  <option>10"</option>
                  <option>15"</option>
                  <option>20"</option>
                  <option>25"</option>
                  <option>30"</option>
                  <option>35"</option>
                  <option>40"</option>
                  <option>45"</option>
                  <option>50"</option>
                  <option>55"</option>
                  <option>60"</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_clave">
                <Form.Label>CLAVE</Form.Label>
                <Form.Control placeholder="CLAVE" type="text" onChange={this.update_value} />
              </Form.Group>
              <Form.Group as={Col} className="form_group" controlId="form_observaciones">
                <Form.Label>OBSERVACIONES</Form.Label>
                <Form.Control placeholder="OBSERVACIONES" type="text" onChange={this.update_value} required />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col colSpan={6}>
                <h1>2. CPM Resultante y Estimación de Contactos</h1>
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} colSpan={1} className="form_group">
                  <Form.Label>CPM TOTAL</Form.Label>
                  <Form.Control value={this.state.cpm_total>=0 ? this.state.cpm_total : "-" } plaintext={true} readOnly={true} type="text" required />
              </Form.Group>

              <Form.Group as={Col} colSpan={1} className="form_group">
                  <Form.Label>CONTACTOS E. TOTALES</Form.Label>
                  <Form.Control value={this.state.contactos_totales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} plaintext={true} readOnly={true} type="text" />
              </Form.Group>              
              
              <Form.Group as={Col} colSpan={2} className="form_group">
              </Form.Group>
            </Row>

            {this.state.submitting ?
              <div>
                <Spinner
                  as="span"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                  variant="secondary"/>
                <span>Loading...</span>
              </div> :              
              <Button variant="primary" disabled={can_submit_form} type="submit">
                RESERVA
              </Button>
            }

            <Row className="mb-3">
              <Col  xs={10}>
                <p className="text-start">Las campañas contratadas se rigen en base a la LGCA 13/2022 del 7 de julio.</p>
                <p className="text-start">La planificación de la campaña se ajustará a la estimación de la petición de compra. La facturación final se realizará en base a los contactos reales resultantes de las audiencias de Kantar Media. Se realizarán seguimientos diarios para ajustar los resultados a las previsiones de la campaña</p>

                <p className='text-start'>La distribución de las campañas, a excepción de El Hormiguero, será:</p>
                <ul>
                  <li>40-50% SECOND TIME (12:00-20:30)</li>
                  <li>50-60% DAY TIME (07:00 -12:30) & PRIME TIME II (00:30 - 02:00)</li>
                </ul>
                <p className='text-start'>Para cada Producto se exige un mínimo de inversión:</p>
                <ul>
                  <li>Antena 3: Mínimo Inversión de 6.000€</li>
                  <li>La sexta: Mínimo Inversión de 6.000€</li>
                  <li>Multi: Mínimo Inversión de 4.000€</li>
                  <li>El Hormiguero: Mínimo Inversión de 35.000€</li>                  
                </ul>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}>
                <ListGroup variant="flush" className="docs">
                  <ListGroup.Item as="div">
                    <h3>Documentos</h3>
                  </ListGroup.Item>

                  <ListGroup.Item action href="/docs/Condiciones_Contratación_Atresdesk_(2023).pdf" as="a" target = "_blank" variant="light">
                    <Image src="/images/pdf.png" className="pdf"/><strong>Condiciones Contratación A3Desk</strong>
                  </ListGroup.Item>

                  <ListGroup.Item action href="/docs/Normas_Recepcion_Material_Publicitario_A3Desk.pdf" target = "_blank" variant="light">
                    <Image src="/images/pdf.png" className="pdf"/><strong>Normas Recepcion Material Publicitario A3Desk</strong>
                  </ListGroup.Item>                
                </ListGroup>
              </Col>
            </Row>

            
          </Form>
        </div>
        <Container className='atres_form_footer'>
          <Row>
            <Col colSpan={6}>
              <CookieAcknowledgement />
            </Col>
          </Row>
            <Row>
              <Col>
                <p className="text-start">Para ayudas relacionadas con soporte técnico y planificación: <a className='white' href="mailto:a3desk@atresmedia.com">a3desk@atresmedia.com</a> </p>
                <p className="text-start">Para ayudas relacionadas con soporte comercial Atresmedia:  <a href="mailto:carlos.delacuadra@atresmedia.com">carlos.delacuadra@atresmedia.com</a></p>
              </Col>
            </Row>
        </Container>
        </div>
        )
    }
}

AtresForm.propTypes = {
  email: PropTypes.string,
  is_confirmation: PropTypes.bool,
  reqid: PropTypes.string,
  token: PropTypes.string
}

export default AtresForm;