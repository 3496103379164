import React, { Component } from 'react';
import PropTypes from "prop-types";
import { getBookings, acknowledgeCampaign, get_local_claims } from '../services/api_caller';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

class BookingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          campaign_list: [],
          token: {"email": "","id_token": "","nonce": ""},
          showMessage: false,
          submit_status: false,
          message: ""
        };
        this.acknowledge_campaign = this.acknowledge_campaign.bind(this);
        this.handle_clave_de_emission_change = this.handle_clave_de_emission_change.bind(this);
    }

    handle_clave_de_emission_change(event, reqid) {
      this.setState({["clave_" + reqid] : event.target.value });
    }

    acknowledge_campaign(campaign){
      let token = this.state.token;
      let acknowledge_payload = {
        reqid: campaign.reqid,
        clave_de_emision: this.state["clave_" + campaign.reqid],
        acknowledged_by: token["email"]
      }

      try{
        acknowledgeCampaign(acknowledge_payload, token).then(acknowledge_response=>{
          getBookings(token).then(campaign_response =>{
            this.setState({campaign_list: campaign_response.campaign_list, showMessage: true, message: "Booking acknowledged!", submit_status: true});
          });
        });
      }
      catch(error){
        this.setState({showMessage: true, message: "Could not acknowledge booking!", submit_status: false});
      }
    }

    componentDidMount() {
      let token = get_local_claims(localStorage);

      getBookings(token).then(campaign_response =>{
        this.setState({campaign_list: campaign_response["campaign_list"], token: token});
      });
    }

    render() {      
      let self = this;
      let campaign_list = (this.state.campaign_list!==null ? this.state.campaign_list : []);
      let token = this.state.token;
      return (
<div className='atres_form_container'>
          <div className="atresmedia_form">
            <Alert className="form_alert" show={this.state.showMessage} variant={this.state.submit_status?"success": "danger"} dismissible onClose={() => this.setState({showMessage: false})} >
              <Alert.Heading>{this.state.submit_status?"Succesful!": "Failed!"}</Alert.Heading>
              <p>{this.state.message}</p>
            </Alert>
            <Table striped bordered hover className='booking_list'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Anunciante</th>
                <th>Marca</th>
                <th>Agencia</th>
                <th>Presupuesto</th>
                <th>Producto</th>
                <th>Date</th>
                <th>Target</th>
                <th>Duración Spot</th>
                <th>Clave</th>
                <th>Observaciones</th>
                <th>CPM Total</th>
                <th>Contactos E. Totales</th>
                <th>Submitted by</th>
                <th>Acknowledged by</th>
                {(token["email"].indexOf("@atresmedia.com")>0 || token["email"].indexOf("@thetradedesk.com")>0) &&
                  <th>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              { campaign_list.map(function(campaign, index){return (
                <tr key={index}>
                  <td>{campaign.reqid}</td>
                  <td>{campaign.anunciante}</td>
                  <td>{campaign.marca}</td>
                  <td>{campaign.agencia}</td>
                  <td>{campaign.presupuesto}</td>
                  <td>{campaign.producto}</td>
                  <td>{campaign.date_range_start} - {campaign.date_range_end}</td>
                  <td>{campaign.target}</td>
                  <td>{campaign.duracion_spot}</td>
                  <td>{campaign.clave}</td>
                  <td>{campaign.observaciones}</td>
                  <td>{campaign.cpm_total}</td>
                  <td>{campaign.contactos_totales}</td>
                  <td>{campaign.submitted_by} ({campaign.submission_date})</td>
                  <td>{campaign.acknowledged?<span>{campaign.acknowledged_by} ({campaign.acknowledged_date})</span>:<span></span>}</td>
                  {/* <td>
                    {campaign.acknowledged? 
                        <span>{campaign.clave_de_emision}</span>:
                        <input type="text" onChange={(e)=>self.handle_clave_de_emission_change(e, campaign.reqid)} />}
                  </td> */}
                  <td>
                    {(token["email"].indexOf("@atresmedia.com")>0 || token["email"].indexOf("@thetradedesk.com")>0) && campaign.acknowledged?
                        <span></span>:
                        // <Button disabled={!self.state["clave_" + campaign.reqid]} onClick={(event)=>self.acknowledge_campaign(campaign)}>Acknowledge</Button>
                        <Button onClick={(event)=>self.acknowledge_campaign(campaign)}>Acknowledge</Button>
                        }
                  </td>
                </tr>
                );} )}
            </tbody>
          </Table>
          </div>
          </div>
      )
    }
}

BookingList.propTypes = {
  user_claims: PropTypes.object,
  is_confirmation: PropTypes.bool,
  reqid: PropTypes.string
}

export default BookingList;