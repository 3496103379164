import React, { Component } from "react";

import AtresForm from './atres_form';
import '../App.css';


export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {first_load: true};
  }
  
  render() {
    return (
          <AtresForm email={localStorage.getItem("email")} token={localStorage.getItem("token")} />
    );
  }
}


export default Home;
