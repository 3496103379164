import { Route, Switch } from "react-router-dom";
import CallBack from '../services/authService/CallBack';
import SilentRenew from '../services/authService/SilentRenew';
import AuthProvider from '../services/authService/AuthContexts';
import Home from '../components/home';
import BookingList from '../components/booking_list';

import MainLayout from '../layouts/MainLayout';
const Router = () => {
    return (
    <Switch>
      <Route path="/" exact>
        <AuthProvider>
          <MainLayout>
            <Home />
          </MainLayout>
        </AuthProvider>
      </Route>
      <Route path="/booking_list" exact>
        <AuthProvider>
          <MainLayout>
            <BookingList />
          </MainLayout>
        </AuthProvider>
      </Route>
      <Route path="/login/callback" render={(props) => <CallBack {...props}/>}></Route>
      <Route path="/login/callback"><SilentRenew /></Route>
    </Switch>);
};
export default Router;

