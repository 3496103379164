import React from "react";
import UserManager from "./UserManager";
import PropTypes from 'prop-types';

export const AuthContext = React.createContext({});
const AuthProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [isSignOut, setIsSignOut] = React.useState(false);
    React.useEffect(() => {
        UserManager.Oidc.events.addUserLoaded(onUserLoaded);
        UserManager.Oidc.events.addUserUnloaded(onUserUnloaded);
        UserManager.Oidc.events.addAccessTokenExpired(onAccessTokenExpired);
        UserManager.Oidc.events.addSilentRenewError(onSilentRenewError);
        return () => {
            UserManager.Oidc.events.removeUserLoaded(onUserLoaded);
            UserManager.Oidc.events.removeUserUnloaded(onUserUnloaded);
            UserManager.Oidc.events.removeAccessTokenExpired(onAccessTokenExpired);
            UserManager.Oidc.events.removeSilentRenewError(onSilentRenewError);
        };
    });
    React.useEffect(() => {
        const onPageLoad = async () => {
            const token = localStorage.getItem("token");
            if (!token && !isSignOut) {
                console.log('Redicrecting...');
                await UserManager.Oidc.signinRedirect();
                console.log('Redirected.....');
                return;
            }
            if (token && !user) {
                const loginUser = await UserManager.Oidc.getUser();
                if (loginUser && loginUser.access_token) {
                    if (typeof window !== "undefined") {
                        await UserManager.Oidc.events.load(loginUser);
                    }
                }
            }
        };
        onPageLoad();
    }, [user, isSignOut]);
    const onUserLoaded = (user) => {
        setUser(user);
    };
    const onUserUnloaded = () => {
        setUser(null);
        localStorage.clear();
        UserManager.Oidc.clearStaleState();
        if (typeof window !== "undefined") {
            window.location.href = "/";
        }
    };
    const onSilentRenewError = (err) => {
        console.error("renew error", err);
        onUserUnloaded();
    };
    const onAccessTokenExpired = async () => {
        await handelSignOutCallback();
    };
    const handelSignInCallback = async () => {
        await UserManager.Oidc.clearStaleState().then(async () => {
            await UserManager.Oidc.signinRedirect();
        });
    };
    const handelSignOutCallback = async () => {
        setIsSignOut(true);
        UserManager.Oidc.signoutRedirect().catch((err) => {
            console.log(err);
            onUserUnloaded();
        });
    };
    const handelRefreshTokenCallback = async () => {
        await UserManager.Oidc.signinSilent().catch((ex) => {
            console.error(ex);
        });
    };
    const value = {
        user,
        isAuthenticated: !!user,
        handelSignInCallback,
        handelSignOutCallback,
        handelRefreshTokenCallback,
    };
    return (<AuthContext.Provider value={value}>
      {value.isAuthenticated ? children : null}
    </AuthContext.Provider>);
};
export const useAuth = () => React.useContext(AuthContext);

AuthProvider.propTypes = {
  initToken: PropTypes.string,
  children: PropTypes.object
};

export default AuthProvider;
