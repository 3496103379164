import React from "react";
import { AuthContext } from '../services/authService/AuthContexts';
import PropTypes from 'prop-types';


import A3DeskNavbar from '../components/a3desk_navbar';

class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: false,
        };
    }
    componentDidMount() {
    }
    render() {
        const { children } = this.props;
        const { handelSignOutCallback } = this.context;
        return (
        <div className="app">
            <div className="app_header">
                <A3DeskNavbar isAuthenticated={true} logout={handelSignOutCallback} login={this.login} />
            </div>
            <div className="app_body">
            {children}
            </div>
      </div>);
    }
}
MainLayout.contextType = AuthContext;

MainLayout.propTypes = {
  children: PropTypes.object
};

export default MainLayout;
