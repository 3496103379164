import { WebStorageStateStore } from "oidc-client";
import { OKTA_CLIENT_ID,  OKTA_ISSUER } from "../environment_constants";

let OidcConfig = {};
if (typeof window !== "undefined") {
  const portalUrl = window.location.origin;
  let okta_client_id = OKTA_CLIENT_ID;
  let okta_redirect_uri = "https://" + window.location.hostname + "/login/callback";  
  if(okta_redirect_uri.indexOf("localhost")>0){
    okta_redirect_uri = "http://localhost:3000/login/callback";
  }

  let okta_authority = OKTA_ISSUER;

  OidcConfig = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: okta_authority,
    client_id: okta_client_id,
    redirect_uri: okta_redirect_uri,
    response_type: "code",
    scope: "openid profile email",
    post_logout_redirect_uri: portalUrl,
    silent_redirect_uri: `${portalUrl}/auth/silent-renew`,
    automaticSilentRenew: true,
  };
}
export { OidcConfig };
